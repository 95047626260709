import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import logoSrc from "../images/studio-face-logo.png"
import { IoLogoInstagram } from "@react-icons/all-files/io5/IoLogoInstagram"
import { IoLogoFacebook } from "@react-icons/all-files/io5/IoLogoFacebook"
import { IoLogoLinkedin } from "@react-icons/all-files/io5/IoLogoLinkedin"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyLinkButton } from "../pages/index"

const FooterWrapper = styled.div`
  min-height: 40vh;
  background: rgba(0,0,0,0.9);
  backdrop-filter: invert(100%) blur(20px);
`

const FooterTransparent = styled.div`
  min-height: 40vh;
  background: rgba(0,0,0,0.3);
`

const FooterLogo = styled.img`
  display: block;
  width: 100%;
  max-width: 9em;
  height: auto;
  margin-bottom: 1em;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 1000px) {
    margin-left: 0;
  }
`

const FooterContent = styled.div`
  color: #808080;
  line-height: 1.5em;
  font-size: 1.1em;
  margin-bottom: 1.5em;
  @media (min-width: 1000px) {
    margin: 0 1em;
    flex: 1;
  }
  a {
    color: inherit;
  }
`

const FooterContentSocial = styled(FooterContent)`
  flex: none;
  a {
    color: #fb578a;
    font-size: 1.8em;
    display: block;
    margin: 0.2em;
  }
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  @media (min-width: 1000px) {
    a {
      margin: 0 0 0.2em 0;
    }
    & > div {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`

const SectionContent = styled.div`
  max-width: 80em;
  margin: 0 auto;
  display: flex;
  padding: 5em 1em;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  @media (min-width: 1000px) {
    flex-direction: row;
    text-align: left;
  }
`

const FooterContactName = styled.div`
  font-family: Raleway, sans-serif;
  font-weight: 200;
  font-size: 1.6em;
  text-indent: -0.05em;
  color: #fff;
  margin-bottom: 0.3em;
  margin-top: 0.15em;
`

const FooterContactTitle = styled.div`
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: bold;
  line-height: 1.1em;
  margin-bottom: 0.4em;
`

const FooterContactEmail = styled.a`
  color: inherit;
  line-height: 1.1em;
  display: block;
`

const SubFooter = styled.div`
  padding: 3em 1em 4em 1em;
  text-align: center;
  background: rgba(0,0,0,0.5);
  color: #505050;
  word-wrap: break-word;
  font-size: 0.8em;
  text-transform: uppercase;
  a {
    color: #808080;
    text-decoration: none;
    margin: 0 0.5em;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
`

const SectionContentBookButton = styled(SectionContent)`
  margin-bottom: 0;
  padding-bottom: 0;
`

const FooterContentBookButton = styled(FooterContent)`
  display: flex;
  justify-content: center;
`

export default function({isIndex, page}) {
    const Wrapper = isIndex ? FooterWrapper : FooterTransparent
    return <Wrapper>
        {(!page || page.slug !== "yhteystiedot") && 
          <SectionContentBookButton>
            <FooterContentBookButton>
              <GatsbyLinkButton to="/yhteystiedot">Bookkaa mallimme</GatsbyLinkButton>
            </FooterContentBookButton>
          </SectionContentBookButton>
        }
        <SectionContent>
            <StaticQuery
              query={graphql`
                query ContactQuery {
                  allContentfulSiteSettings {
                    edges {
                      node {
                        footerContent {
                          raw
                        }
                        contacts {
                          name
                          title
                          email
                          phone
                        }
                      }
                    }
                  }
                }
              `}
              render={(data) => {
                return <>
                <FooterContent>
                  <Fade bottom>
                    <FooterLogo src={logoSrc} />
                    <>
                      {renderRichText(data.allContentfulSiteSettings.edges[0].node.footerContent)}
                    </>
                  </Fade>
                </FooterContent>
                {data.allContentfulSiteSettings.edges[0].node.contacts.map(({name, title, email, phone}) =>      
                  <FooterContent key={email}>
                    <Fade bottom>
                      <>
                        <FooterContactName>{name}</FooterContactName>
                        <FooterContactTitle>{title}</FooterContactTitle>
                        <FooterContactEmail href={"mailto:" + email}>{email}</FooterContactEmail>
                        {phone && <FooterContactEmail href={"tel:" + phone.replace(" ", "")}>{phone}</FooterContactEmail>}
                      </>
                    </Fade>
                  </FooterContent>
                )}
              </>}}
            />
            <FooterContentSocial>
              <Fade bottom>
                <>
                  <a href="http://www.facebook.com/pages/Studio-Face/114744868622003" target="_blank" rel="noopener noreferrer"><IoLogoFacebook /></a>
                  <a href="https://www.instagram.com/studiofacemodelagency" target="_blank" rel="noopener noreferrer"><IoLogoInstagram /></a>
                  <a href="https://www.linkedin.com/company/studio-face-model-agency" target="_blank" rel="noopener noreferrer"><IoLogoLinkedin /></a>
                </>
              </Fade>
            </FooterContentSocial>
        </SectionContent>
        <SubFooter>
          <a href="http://www.kauneuskilpailut.fi/" target="_blank" rel="noopener noreferrer">Kauneuskilpailut.fi</a> &bull; 
          <a href="http://www.muotilive.fi/" target="_blank" rel="noopener noreferrer">Muotilive.fi</a> &bull; 
          <a href="https://www.facebook.com/castingfinland/" target="_blank" rel="noopener noreferrer">Casting Finland</a> &bull; 
          <a href="https://misstampere.fi/" target="_blank" rel="noopener noreferrer">Miss Tampere</a> &bull; 
          <a href="https://misshameenlinna.fi/" target="_blank" rel="noopener noreferrer">Miss Hämeenlinna</a> &bull; 
          <a href="http://missnokia.fi/" target="_blank" rel="noopener noreferrer">Miss Nokia</a> &bull; 
          <a href="https://missylojarvi.fi/" target="_blank" rel="noopener noreferrer">Miss Ylöjärvi</a>
        </SubFooter>
    </Wrapper>
}
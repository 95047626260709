import * as React from "react"
import styled, { keyframes } from "styled-components"
import Fade from "react-reveal/Fade"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"
import heroVideoSrc from "../images/studioface-bnw.mp4"
import heroLogoSrc from "../images/studio-face-logo.png"
import CaretRight from "../components/caret-right"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { Link, useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

const contentOptions = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return <Link to={"/" + node.data.target.slug}>{children}</Link>
    },
  },
}

const HeroVideo = styled.video`
  position: fixed;
  display: block;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
`

const Content = styled.div`
  position: relative;
`

const Hero = styled.div`
  min-height: calc(100vh - 5em);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const HeroLogo = styled.img`
  display: block;
  width: 80%;
  max-width: 40em;
  margin: 0 auto;
`

const Section = styled.div`
  background: rgba(0,0,0,0.7);
  backdrop-filter: blur(20px);
`

const SectionContent = styled.div`
  max-width: 80em;
  margin: 0 auto;
  display: flex;
  padding: 2em 0;
  flex-direction: column;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 2em;
  h2 {
    color: #fb578a;
    font-size: 1.3em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0;
  }
  p, ul {
    flex: 1;
    margin: 2em 0;
    line-height: 1.8em;
  }
  li p {
    margin: 0;
    padding: 0;
  }
  p:empty {
    display: none;
  }
  color: #e2e2e2;
  margin: 3em auto;
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  @media (min-width: 1000px) {
    width: auto;
  }
`

const buttonFlash = keyframes`
  0% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
`

const Button = styled.a`
  display: flex;
  border: 1px solid #fb578a;
  color: #fb578a;
  padding: 1em;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 400;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: transparent;
    animation: ${buttonFlash} 250ms ease-out;
    border-color: #fff;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
  }
`

export const GatsbyLinkButton = styled(Link)`
  display: flex;
  border: 1px solid #fb578a;
  color: #fb578a !important;
  padding: 1em;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 400;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: transparent;
    animation: ${buttonFlash} 250ms ease-out;
    border-color: #fff;
    cursor: pointer;
    color: #fff !important;
    text-decoration: none;
  }
`

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      allContentfulSiteSettings {
        edges {
          node {
            homeContent {
              title
              content {
                raw
              }
              linkText
              linkToPage {
                slug
              }
              linkUrl
            }
          }
        }
      }
    }
  `)
  const site = data.allContentfulSiteSettings.edges[0].node
  return (
    <ParallaxProvider>
      <Helmet>
      </Helmet>
      <Layout hideHeaderOnMobile isIndex>
        <HeroVideo playsInline autoPlay muted loop>
          <source src={heroVideoSrc} type="video/mp4" />
        </HeroVideo>
        <Content>
          <Hero>
            <Parallax y={[-80, 80]}>
              <HeroLogo src={heroLogoSrc} />
            </Parallax>
          </Hero>
          <Section>
            <SectionContent>
              {site.homeContent.map(({title, content, linkText, linkUrl, linkToPage}, i) => 
                <TextBlock key={i}>
                  <Fade bottom>
                    <>
                      <h2>{title}</h2>
                      {content && renderRichText(content, contentOptions)}
                      {(linkText && (linkUrl || linkToPage)) &&
                        <GatsbyLinkButton to={linkUrl || ("/" + linkToPage.slug)}>{linkText}<CaretRight /></GatsbyLinkButton>
                      }
                    </>
                  </Fade>
                </TextBlock>
              )}
            </SectionContent>
          </Section>
        </Content>
      </Layout>
    </ParallaxProvider>
  )
}

export default IndexPage

import React from "react"
import styled from "styled-components"
import Header from "../components/header"
import Footer from "../components/footer"
import Helmet from "react-helmet"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const LayoutWrapper = styled.div`

`

export default function({children, hideHeaderOnMobile, isIndex, page}) {
    return <LayoutWrapper>
        <Helmet titleTemplate="Studio Face - %s" defaultTitle="Studio Face">
            <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, minimum-scale=1, maximum-scale=1" />
        </Helmet>
        <Header hideHeaderOnMobile={hideHeaderOnMobile} />
        {children}
        <Footer isIndex={isIndex} page={page} />
        <CookieConsent
            location="bottom"
            buttonText="Jatka"
            style={{ background: "#000" }}
            buttonStyle={{ background: "transparent", color: "#fb578a", fontSize: "13px", textTransform: "uppercase", letterSpacing: "0.1em", border: "1px solid #fb578a" }}
            expires={150}>
            Tämä sivusto käyttää evästeitä. <Link to="/tietosuoja">Lue rekisteri- ja tietosuojaseloste</Link>
        </CookieConsent>
    </LayoutWrapper>
}